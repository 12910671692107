import { template as template_2b0ee0e8c64c49a19f7922a18e236c21 } from "@ember/template-compiler";
const FKText = template_2b0ee0e8c64c49a19f7922a18e236c21(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
