import { template as template_af668c58f88c4a948456cf3d34e66475 } from "@ember/template-compiler";
const SidebarSectionMessage = template_af668c58f88c4a948456cf3d34e66475(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
