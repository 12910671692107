import { template as template_213bba390e514b53a46c0c48de8309ba } from "@ember/template-compiler";
const FKLabel = template_213bba390e514b53a46c0c48de8309ba(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
