import { template as template_8f2b2450ece44a9588c407935b8eb8b9 } from "@ember/template-compiler";
const WelcomeHeader = template_8f2b2450ece44a9588c407935b8eb8b9(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
